import { withNamespaces } from 'react-i18next';
import { connect } from 'react-redux';
import { Row, Col, Card, CardBody, Label, Button, ButtonDropdown, DropdownItem, DropdownMenu, DropdownToggle, Alert } from 'reactstrap';
import { AvForm, AvField, AvGroup, AvInput, AvFeedback } from "availity-reactstrap-validation";
import Select from "react-select";
import { useMutation, useQuery } from '@tanstack/react-query';
import IndustriesService from "src/modules/3rd-party-management/apis/IndustriesService";
import React, { useEffect, useState } from 'react';
import Spinner from 'react-bootstrap/Spinner';
import { toast } from "react-toastify";
import ThirdpartyUsersService from "src/modules/3rd-party-management/apis/ThirdpartyUsersService";
import { RiDeleteBin5Line } from "react-icons/ri";
import SupplierService from 'src/modules/3rd-party-management/apis/thirdparty/SupplierService';
import PhoneInput from "react-phone-input-2";
import CreateAndEditModal from './components/CreateAndEditModal';

const CompanyDetails = ({
    t,
    onSubmitSucceed,
    Organization
}) => {
    const [ countries, setCountries ] = useState([]);
    const [ industries, setIndustries ] = useState([]);
	const [ companyDetails, setCompanyDetails ] = useState({});
    const [ officeAddresses, setOfficeAddresses ] = useState([{}]);
    const [ factoryAddresses, setFactoryAddresses ] = useState([{}]);
    const [ shareholders, setShareholders ] = useState({
        individual    :   [],
        company       :   []
    });
    const [ responsiblePerson, setResponsiblePerson ] = useState({});
    const [ responsiblePersonTitleDrop, setResponsiblePersonTitleDrop ] = useState(false);
    const [ showShareholderModal, setShowShareholderModal ] = useState(false);
    const [ selectedShareholderToEdit, setSelectedShareholderToEdit ] = useState(null);

    const handleFetchSupplierGeneralInformationQuery = useQuery({
        queryKey: ['3rd-party-management-fetch-supplier-general-information-query'],
        queryFn: async () => {
            const service = SupplierService.getInstance();

            return await service.fetchGeneralInformation();
        },
        cacheTime: 0,
        refetchOnWindowFocus: false,
        enabled:false,
        onError: (error) => {
            toast(t('An error occurred while fetching supplier information.'), {
                type: 'error',
            });
        }
	});

    const handleFetchIndustriesListQuery = useQuery({
		queryKey: ['3rd-party-management-fetch-industries-list-query'],
		queryFn: async () => {
			const service = IndustriesService.getInstance();

			return await service.fetchList();
		},
		cacheTime: 0,
		refetchOnWindowFocus: false,
		onError: (error) => {
			toast(t('An error occurred while fetching industires list.'), {
				type: 'error',
			});
		}
	});

    const handleFetchCountriesListQuery = useQuery({
		queryKey: ['3rd-party-management-fetch-coutries-list-query'],
		queryFn: async () => {
			const service = ThirdpartyUsersService.getInstance();

			return await service.fetchCountries();
		},
		cacheTime: 0,
		refetchOnWindowFocus: false,
		onError: (error) => {
			toast(t('An error occurred while fetching countries list.'), {
				type: 'error',
			});
		}
	});

    const handleUpdateSupplierGeneralInformationMutate = useMutation({
        mutationFn: async (payload) => {
            const service = SupplierService.getInstance();

            return await service.updateGeneralInformation(payload);
        },
        onSuccess: () => {
            onSubmitSucceed && onSubmitSucceed();
        },
        onError: () => {
            toast(t("An error occurred while updating company details."), {
                type: "error",
            });
        }
    });

    const getLanguageName = (id) => ((Organization?.languages || []).find((lng) => lng?.language?.id === id))?.name

    const handleValidSubmit = (e, values) => {
        const locations = ((values.office_addresses || []).map((address) => {
            return {
                ...address,
                type        :   'office',
                locationId  :   address.locationId || undefined
            }
        })).concat((values.factory_addresses || []).map((address) => {
            return {
                ...address,
                type        :   'manufacture',
                locationId  :   address.locationId || undefined
            }
        }));

        handleUpdateSupplierGeneralInformationMutate.mutate({
            generalInfo         :   values.company_details,
            responsiblePerson   :   values.responsible_person,
            locations           :   locations,
            individualShareHolders  :   shareholders.individual,
            companyShareHolders     :   shareholders.company,
        });
    }

    useEffect(() => {
		if(handleFetchIndustriesListQuery?.data){
			const data = Array.isArray(handleFetchIndustriesListQuery.data) ? handleFetchIndustriesListQuery.data : [];

			const list = data.map((industry) => {
                return {
                    value : industry.id,
                    label : t(industry.title)
                };
			});

            setIndustries(list);
		}
	}, [ handleFetchIndustriesListQuery.data ]);

    useEffect(() => {
		if(handleFetchCountriesListQuery?.data){
			const data = Array.isArray(handleFetchCountriesListQuery.data) ? handleFetchCountriesListQuery.data : [];

			const list = data.map((country) => {
                return {
                    value   :   country.id,
                    label   :   t(country.name),
                    code    :   country.code,
                };
			});

            setCountries(list);
		}
	}, [ handleFetchCountriesListQuery.data ]);

    useEffect(() => {
        if(handleFetchSupplierGeneralInformationQuery.data){
            const {
                companyDetail,
                locations,
                responsiblePerson   :   responsiblePersonData
            } = handleFetchSupplierGeneralInformationQuery.data;

            setCompanyDetails({
                name        :   companyDetail.name,
                industry    :   {
                    value   :   companyDetail.industry?.id,
                    label   :   t(companyDetail.industry?.name)
                },
                companySize :   {
                    value   :   companyDetail.companySize,
                    label   :   companyDetail.companySize + " " + t("Employees")
                },
                websiteUrl  :   companyDetail.websiteUrl
            });

            setOfficeAddresses([
                ...((locations || []).filter((location) => location.type === 'office').map((location, i) => {
                    return {
                        locationId  :   location.id,
                        country     :   {
                            value   :   location.country,
                            label   :   countries.find((c) => c.value === location.country)?.label
                        },
                        city        :   location.city,
                        address     :   location.address
                    }
                }))
            ]);

            setFactoryAddresses([
                ...((locations || []).filter((location) => location.type === 'manufacture').map((location, i) => {
                    return {
                        locationId  :   location.id,
                        country     :   {
                            value   :   location.country,
                            label   :   countries.find((c) => c.value === location.country)?.label
                        },
                        city        :   location.city,
                        address     :   location.address
                    }
                }))
            ]);

            setResponsiblePerson({
                ...responsiblePersonData,
                language    :   {
                    value   :   responsiblePersonData.language,
                    label   :   getLanguageName(responsiblePersonData.language)
                }
            });
        }
    }, [ handleFetchSupplierGeneralInformationQuery.data ]);

    useEffect(() => {
        if(industries.length > 0 && countries.length > 0){
            handleFetchSupplierGeneralInformationQuery.refetch();
        }
    }, [ industries, countries ]);

    useEffect(() => {
        if(!selectedShareholderToEdit){
            setShowShareholderModal(false)
        }
        else{
            setShowShareholderModal(true)
        }
    }, [selectedShareholderToEdit])
 

    const industriesListIsLoading = handleFetchIndustriesListQuery.isFetching || handleFetchIndustriesListQuery.isLoading;
    const countriesListIsLoading = handleFetchCountriesListQuery.isFetching || handleFetchCountriesListQuery.isLoading;

    const isFormSubmiting = handleUpdateSupplierGeneralInformationMutate.isLoading || handleUpdateSupplierGeneralInformationMutate.isFetching;

    const isDetailsLoading = handleFetchSupplierGeneralInformationQuery.isFetching || handleFetchSupplierGeneralInformationQuery.isLoading;

    const languages = (Organization?.languages || []).map((lng) => {
        return {
            value   :   lng?.language?.id,
            label   :   t(lng.name),
            code    :   lng.language_code,
        }
    });

    return (
        <React.Fragment>
            <CreateAndEditModal
                show={showShareholderModal}
                toggle={() => {
                    setShowShareholderModal(false)
                }}
                onSubmit={(type, values, action) => {
                    if(action === 'create'){
                        setShareholders((currentHolders) => {
                            return {
                                ...currentHolders,
                                [type] : [...currentHolders[type], {...values, type : type}]
                            }
                        })
                    }
                    else{
                        setShareholders((currentHolders) => {
                            const newList = [...currentHolders[type]]
                            newList[values.index] = {
                                ...values,
                                type : type
                            }

                            return {
                                ...currentHolders,
                                [type] : newList
                            }
                        })
                    }

                    setShowShareholderModal(false)
                }}
                countries={countries}
                shareholder={selectedShareholderToEdit}
                onClosed={() => setSelectedShareholderToEdit(null)}
            />

            <AvForm onValidSubmit={ handleValidSubmit } className="needs-validation m-2">
                <Card className="wizard-steps-container">
                    <CardBody>
                        <h4>{t("General Information")}</h4>

                        <div className="mb-4">
                            <Row>
                                <Col md="3" sm="12">
                                    <Label for="company-details-name">
                                        {t("Company Name")}
                                    </Label>
                                    <AvField
                                        disabled={ isDetailsLoading }
                                        id="company-details-name"
                                        required
                                        name={"company_details[name]"}
                                        type="text"
                                        errorMessage={t("This field cannot be blank")}
                                        className="form-control"
                                        validate={{
                                            required: { value: true },
                                        }}
                                        value={companyDetails?.name}
                                    />
                                </Col>

                                <Col md="3" sm="12">
                                    <Label for="company-details-industry">
                                        {t("Industry")}
                                    </Label>

                                    <Select
                                        // isDisabled={ industriesListIsLoading || isDetailsLoading }
                                        isDisabled={ true }
                                        isLoading={ industriesListIsLoading || isDetailsLoading }
                                        placeholder={t("Select") + "..."}
                                        classNamePrefix="select2-selection"
                                        id='company-details-industry'
                                        options={industries}
                                        menuPortalTarget={document.body}
                                        onChange={(e) => {
                                            setCompanyDetails((companyDetails) => {
                                                return {
                                                    ...companyDetails,
                                                    industry	:	e
                                                }
                                            });
                                        }}
                                        value={companyDetails.industry}
                                    />

                                    <AvField
                                        name={"company_details[industry]"}
                                        type="hidden"
                                        errorMessage={t("This field cannot be blank")}
                                        className="form-control"
                                        validate={{
                                            required: { value: true },
                                        }}
                                        value={companyDetails?.industry?.value}
                                    />
                                </Col>

                                <Col md="3" sm="12">
                                    <Label for="company-details-size">
                                        {t("Company Size")}
                                    </Label>
                                    <Select
                                        isDisabled={ isDetailsLoading }
                                        isLoading={ isDetailsLoading }
                                        placeholder={t("Select") + "..."}
                                        classNamePrefix="select2-selection"
                                        id='company-details-size'
                                        options={[
                                            {
                                                value: "0-10",
                                                label: "0-10 " + t("Employees"),
                                            },
                                            {
                                                value: "10-50",
                                                label: "10-50 " + t("Employees"),
                                            },
                                            {
                                                value: "50-100",
                                                label: "50-100 " + t("Employees"),
                                            },
                                            {
                                                value: "100-500",
                                                label: "100-500 " + t("Employees"),
                                            }
                                        ]}
                                        menuPortalTarget={document.body}
                                        onChange={(e) => {
                                            setCompanyDetails((companyDetails) => {
                                                return {
                                                    ...companyDetails,
                                                    companySize	:	e
                                                }
                                            });
                                        }}
                                        value={companyDetails.companySize}
                                    />
                                    <AvField name={"company_details[companySize]"}
                                        type="hidden"
                                        errorMessage={t("This field cannot be blank")}
                                        validate={{
                                            required: { value: true },
                                        }}
                                        value={companyDetails?.companySize?.value}
                                    />
                                </Col>

                                <Col md="3" sm="12">
                                    <Label for="company-details-website">
                                        {t("Website")}
                                    </Label>
                                    <AvField name={"company_details[websiteUrl]"}
                                        disabled={ isDetailsLoading }
                                        placeholder=""
                                        type="text"
                                        errorMessage={t("This field cannot be blank")}
                                        className="form-control"
                                        validate={{
                                            required: { value: true },
                                        }}
                                        id="company-details-website"
                                        value={companyDetails?.websiteUrl}
                                    />
                                </Col>
                            </Row>
                        </div>

                        <div className="mb-4">
                            <Label>
                                { `${t('Office Address')}: ` }
                            </Label>
                            {officeAddresses.map((item, i) => {
                                return (
                                    <Row className="mb-2" key={i}>
                                        <AvInput 
                                            name={`office_addresses[${i}][locationId]`}
                                            type="hidden"
                                            value={ item?.locationId }
                                        />

                                        <Col md="3" sm="12">
                                            <Select
                                                isDisabled={ countriesListIsLoading || isDetailsLoading }
                                                isLoading={ countriesListIsLoading || isDetailsLoading }
                                                placeholder={t("Country")}
                                                classNamePrefix="select2-selection"
                                                options={countries}
                                                menuPortalTarget={document.body}
                                                onChange={(e) => {
                                                    setOfficeAddresses((currentAddresses) => {
                                                        const newAddresses = [...currentAddresses];
                                                        newAddresses[i] = { ...newAddresses[i], country: e };
                                                        return newAddresses;
                                                    });
                                                }}
                                                value={officeAddresses[i]?.country}
                                            />

                                            <AvField
                                                name={`office_addresses[${i}][country]`}
                                                type="hidden"
                                                errorMessage={t("This field cannot be blank")}
                                                className="form-control"
                                                validate={{
                                                    required: { value: true },
                                                }}
                                                value={officeAddresses[i]?.country?.value}
                                            />
                                        </Col>

                                        <Col md="3" sm="12">
                                            <AvField
                                                name={`office_addresses[${i}][city]`}
                                                placeholder={t("City")}
                                                type="text"
                                                errorMessage={t("This field cannot be blank")}
                                                className="form-control"
                                                validate={{
                                                    required: { value: true },
                                                }}
                                                onChange={(e) => {
                                                    officeAddresses[i].city = e.target.value
                                                }}
                                                value={officeAddresses[i]?.city || ''}
                                                disabled={ isDetailsLoading }
                                            />
                                        </Col>

                                        <Col md="5" sm="12">
                                            <AvField
                                                name={`office_addresses[${i}][address]`}
                                                placeholder={t("Address")}
                                                type="text"
                                                errorMessage={t("This field cannot be blank")}
                                                className="form-control"
                                                onChange={(e) => {
                                                    officeAddresses[i].address = e.target.value
                                                }}
                                                validate={{
                                                    required: { value: true },
                                                }}
                                                value={officeAddresses[i]?.address || ''}
                                                disabled={ isDetailsLoading }
                                            />
                                        </Col>

                                        <Col md="1" sm="12" hidden={officeAddresses.length <= 1}>
                                            <h4 style={{ cursor: "pointer" }} className="p-1 text-danger" >
                                                <RiDeleteBin5Line onClick={() => {
                                                    setOfficeAddresses([
                                                        ...officeAddresses.filter((_, ix) => ix !== i)
                                                    ]);
                                                }} />
                                            </h4>
                                        </Col>
                                    </Row>
                                );
                            })}

                            <Row className="mt-3">
                                <Col sm="12" style={{ display: "flex", justifyContent: "flex-end" }}>
                                    <Button color="primary"
                                        onClick={() => {
                                            setOfficeAddresses([
                                                ...officeAddresses,
                                                {}
                                            ])
                                        }}
                                        outline>
                                        {" + " + t("Add Location")}
                                    </Button>
                                </Col>
                            </Row>
                        </div>

                        <div className="mb-4">
                            <Label>
                                { `${t('Factory Location')}: ` }
                            </Label>
                            
                            {factoryAddresses.map((item, i) => {
                                return (
                                    <Row className="mb-2" key={i}>
                                        <AvInput 
                                            name={`factory_addresses[${i}][locationId]`}
                                            type="hidden"
                                            value={ item?.locationId }
                                        />

                                        <Col md="3" sm="12">
                                            <Select placeholder={t("Country")}
                                                isDisabled={ countriesListIsLoading || isDetailsLoading }
                                                isLoading={ countriesListIsLoading || isDetailsLoading }
                                                classNamePrefix="select2-selection"
                                                id={`factoryCountry-${i}`}
                                                options={countries}
                                                menuPortalTarget={document.body}
                                                onChange={(e) => {
                                                    setFactoryAddresses((currentAddresses) => {
                                                        const newAddresses = [...currentAddresses];
                                                        newAddresses[i] = { ...newAddresses[i], country: e };
                                                        return newAddresses;
                                                    });
                                                }}
                                                value={factoryAddresses[i]?.country || null}
                                            />
                                            <AvField name={`factory_addresses[${i}][country]`}
                                                type="hidden"
                                                errorMessage={t("This field cannot be blank")}
                                                className="form-control"
                                                validate={{
                                                    required: { value: true },
                                                }}
                                                value={factoryAddresses[i]?.country?.value || null}
                                            />
                                        </Col>

                                        <Col md="3" sm="12">
                                            <AvField name={`factory_addresses[${i}][city]`}
                                                placeholder={t("City")}
                                                type="text"
                                                errorMessage={t("This field cannot be blank")}
                                                className="form-control"
                                                validate={{
                                                    required: { value: true },
                                                }}
                                                onChange={(e) => {
                                                    factoryAddresses[i].city = e.target.value;
                                                }}
                                                value={factoryAddresses[i]?.city || ''}
                                                disabled={ isDetailsLoading }
                                            />
                                        </Col>

                                        <Col md="5" sm="12">
                                            <AvField name={`factory_addresses[${i}][address]`}
                                                placeholder={t("Address")}
                                                type="text"
                                                errorMessage={t("This field cannot be blank")}
                                                className="form-control"
                                                validate={{
                                                    required: { value: true },
                                                }}
                                                onChange={(e) => {
                                                    factoryAddresses[i].address = e.target.value;
                                                }}
                                                value={factoryAddresses[i]?.address || ''}
                                                disabled={ isDetailsLoading }
                                            />
                                        </Col>

                                        <Col md="1" sm="12" hidden={factoryAddresses.length <= 1}>
                                            <h4 style={{ cursor: "pointer" }} className="p-1 text-danger">
                                                <RiDeleteBin5Line onClick={() => {
                                                    setFactoryAddresses([
                                                        ...factoryAddresses.filter((_, ix) => ix !== i)
                                                    ]);
                                                }} />
                                            </h4>
                                        </Col>
                                    </Row>
                                );
                            })}

                            <Row className="mt-3">
                                <Col sm="12" style={{ display: "flex", justifyContent: "flex-end" }}>
                                    <Button color="primary"
                                        onClick={() => {
                                            setFactoryAddresses([
                                                ...factoryAddresses,
                                                {}
                                            ])
                                        }}
                                        outline>
                                        {" + " + t("Add Location")}
                                    </Button>
                                </Col>
                            </Row>
                        </div>

                        <div className="mb-4">
                            <Label>
                                { `${t('Responsible Person')}: ` }
                            </Label>

                            <Row>
                                <Col sm="12">
                                    <Row className="mb-2">
                                        <Col md="4" sm="4">
                                            <Label for="responsible-person-firstname">
                                                {t("First Name")}
                                            </Label>

                                            <AvGroup>
                                                <div className="d-flex flex-wrap position-relative">
                                                    <ButtonDropdown className="position-absolute" 
                                                        isOpen={ responsiblePersonTitleDrop } 
                                                        toggle={() => {
                                                            setResponsiblePersonTitleDrop(!responsiblePersonTitleDrop)
                                                        }} 
                                                        style={{
                                                            left: "0px",
                                                            top: "0px",
                                                            width: "74px"
                                                        }}>
                                                            <DropdownToggle outline split style={{ border: 'none', borderRight: '1px solid rgb(206, 212, 218)' }}>
                                                                <span style={{marginRight: '8px'}}>
                                                                    { t(responsiblePerson.title || 'Mr.') }
                                                                </span>
                                                            </DropdownToggle>

                                                            <DropdownMenu>
                                                                <DropdownItem onClick={() => { 
                                                                    setResponsiblePerson({
                                                                        ...responsiblePerson,
                                                                        title   :   'Mr.'
                                                                    });
                                                                }}>
                                                                    { t('Mr.') }
                                                                </DropdownItem>

                                                                <DropdownItem onClick={() => { 
                                                                    setResponsiblePerson({
                                                                        ...responsiblePerson,
                                                                        title   :   'Mrs.'
                                                                    });
                                                                }}>
                                                                    { t('Mrs.') }
                                                                </DropdownItem>
                                                            </DropdownMenu>
                                                    </ButtonDropdown>

                                                    <AvInput 
                                                        id={`responsible-person-title`}
                                                        name={`responsible_person[title]`}
                                                        type="hidden"
                                                        value={ responsiblePerson.title || 'Mr.'}
                                                    />
                                        
                                                    <AvInput id={`responsible-person-firstname`}
                                                        required
                                                        name={`responsible_person[name]`}
                                                        type="text"
                                                        className="form-control"
                                                        style={{
                                                            paddingLeft: '78px',
                                                            flexBasis : '100%'
                                                        }}
                                                        value={ responsiblePerson?.name }
                                                        disabled={ isDetailsLoading }
                                                    />

                                                    <AvFeedback style={{
                                                        flexBasis : '100%'
                                                    }}>
                                                        {t("This field cannot be blank")}
                                                    </AvFeedback>
                                                </div>
                                            </AvGroup>
                                        </Col>

                                        <Col md="4" sm="12">
                                            <Label for="responsible-person-lastname">
                                                {t("Last Name")}
                                            </Label>
                                            <AvField
                                                name={`responsible_person[lastName]`}
                                                type="text"
                                                errorMessage={t("This field cannot be blank")}
                                                className="form-control"
                                                validate={{
                                                    required: { value: true },
                                                }}
                                                id='responsible-person-lastname'
                                                value={ responsiblePerson?.lastName }
                                                disabled={ isDetailsLoading }
                                            />
                                        </Col>

                                        <Col md="4" sm="12">
                                            <Label for="responsible-person-position">
                                                {t("Position")}
                                            </Label>
                                            <AvField
                                                name={`responsible_person[position]`}
                                                type="text"
                                                errorMessage={t("This field cannot be blank")}
                                                className="form-control"
                                                validate={{
                                                    required: { value: true },
                                                }}
                                                id='responsible-person-position'
                                                value={ responsiblePerson?.position }
                                                disabled={ isDetailsLoading }
                                            />
                                        </Col>
                                    </Row>

                                    <Row className="mb-2">
                                        <Col md="4" sm="12">
                                            <Label for="responsible-person-language">
                                                {t("Language")}
                                            </Label>
                                            <Select
                                                isDisabled={ isDetailsLoading }
                                                isLoading={ isDetailsLoading }
                                                placeholder={t("Select") + "..."}
                                                classNamePrefix="select2-selection"
                                                id='responsible-person-language'
                                                options={ languages }
                                                menuPortalTarget={document.body}
                                                onChange={(e) => {
                                                    setResponsiblePerson({
                                                        ...responsiblePerson,
                                                        language    :   e
                                                    });
                                                }}
                                                value={ responsiblePerson?.language }
                                            />
                                            <AvField
                                                name={`responsible_person[language]`}
                                                type="hidden"
                                                errorMessage={t("This field cannot be blank")}
                                                validate={{
                                                    required: { value: true },
                                                }}
                                                value={responsiblePerson?.language?.value}
                                            />
                                        </Col>

                                        <Col md="4" sm="12">
                                            <Label for="responsible-person-email">
                                                {t("Email")}
                                            </Label>
                                            <AvField
                                                name={`responsible_person[email]`}
                                                type="email"
                                                className="form-control"
                                                validate={{
                                                    required: {
                                                        value: true,
                                                        errorMessage: t("This field cannot be blank"),
                                                    },
                                                    email: { errorMessage: t("Your email is invalid") },
                                                }}
                                                id={`responsible-person-email`}
                                                value={responsiblePerson?.email}
                                                disabled={ true }
                                            />
                                        </Col>

                                        <Col md="4" sm="12">
                                            <Label for="responsible-person-phone">
                                                {t("Phone Number")}
                                            </Label>

                                            <PhoneInput
                                                inputClass="w-100"
                                                country={ "us" }
                                                inputStyle={{
                                                    height  :   '38px'
                                                }}
                                                disableInitialCountryGuess={ false }
                                                disableCountryGuess={ false }
                                                onChange={ (e) => {
                                                    setResponsiblePerson({
                                                        ...responsiblePerson,
                                                        phoneNumber    :   e
                                                    });
                                                }}
                                                value={ responsiblePerson?.phoneNumber }
                                                disabled={ isDetailsLoading }
                                            />

                                            <AvField
                                                name={`responsible_person[phoneNumber]`}
                                                type="hidden"
                                                errorMessage={t("This field cannot be blank")}
                                                validate={{
                                                    required: { value: true },
                                                }}
                                                id={`responsible-person-phone`}
                                                value={ responsiblePerson?.phoneNumber }
                                            />
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </div>

                        <div className="mb-4">
                            <Label>
                                { `${t('Shareholders')}: ` }
                            </Label>

                            <Row>
                                <Col sm='12'>
                                    <Label>
                                        {t('Individual')}
                                    </Label>
                                    <div className='table-container'>
                                        <table className='border-0'>
                                            <thead>
                                                    <tr>
                                                        <th>#</th>
                                                        <th>{t('Title')}</th>
                                                        <th>{t('First Name')}</th>
                                                        <th>{t('Last Name')}</th>
                                                        <th>{t('Name In Local Language')}</th>
                                                        <th>{t('Last Name In Local Language')}</th>
                                                        <th>{t('Birthday')}</th>
                                                        <th>{t('Resident In Country')}</th>
                                                        <th>{t('Percentage')}</th>
                                                        <th>{t('UBO')}</th>
                                                        <th>{t('Action')}</th>
                                                    </tr>
                                            </thead>
                                            <tbody>
                                                {!shareholders.individual.length && (
                                                    <tr>
                                                        <td colSpan={11}>
                                                            <Alert className='text-center' color='warning'>
                                                                { t('This list is empty') }
                                                            </Alert>
                                                        </td>
                                                    </tr>
                                                )}
                                                {shareholders.individual.map((item, i) => {
                                                    const country = countries.find((c) => c.value === item.residenceCountry)

                                                    return (
                                                        <tr>
                                                            <td>{i + 1}</td>
                                                            <td>{item.title}</td>
                                                            <td>{item.firstName}</td>
                                                            <td>{item.lastName}</td>
                                                            <td>{item.localLanguageFirstName}</td>
                                                            <td>{item.localLanguageLastName}</td>
                                                            <td>{item.birthDay}</td>
                                                            <td>{country?.label}</td>
                                                            <td>{item.percentage}%</td>
                                                            <td>{item.isUbo ? t('Yes') : t('No')}</td>
                                                            <td>
                                                                <Button onClick={() => {
                                                                    setSelectedShareholderToEdit({
                                                                        ...item,
                                                                        index : i
                                                                    })
                                                                }} outline color="primary" size="md" className="border-0">
                                                                    <i className="ri-pencil-line font-size-20"></i>
                                                                </Button>

                                                                <Button onClick={() => {
                                                                    setShareholders((currentHolders) => {
                                                                        const newList = [...currentHolders['individual']];
                                                                        newList.splice(i, 1);

                                                                        return {
                                                                            ...currentHolders,
                                                                            'individual' : newList
                                                                        }
                                                                    })
                                                                }} type='button' outline color="danger" size="md" className="border-0">
                                                                    <i className="ri-delete-bin-line font-size-20"></i>
                                                                </Button>
                                                            </td>
                                                        </tr>
                                                    )
                                                })}
                                            </tbody>
                                        </table>     
                                    </div>
                                </Col>
                                <Col sm='12'>
                                    <Label>
                                        {t('Company')}
                                    </Label>
                                    <div className='table-container'>
                                        <table className='border-0'>
                                            <thead>
                                                    <tr>
                                                        <th>#</th>
                                                        <th>{t('Name')}</th>
                                                        <th>{t('Name In Local Language')}</th>
                                                        <th>{t('Founding Date')}</th>
                                                        <th>{t('Country')}</th>
                                                        <th>{t('Percentage')}</th>
                                                        <th>{t('Action')}</th>
                                                    </tr>
                                            </thead>
                                            <tbody>
                                                {!shareholders.company.length && (
                                                    <tr>
                                                        <td colSpan={11}>
                                                            <Alert className='text-center' color='warning'>
                                                                { t('This list is empty') }
                                                            </Alert>
                                                        </td>
                                                    </tr>
                                                )}

                                                {shareholders.company.map((item, i) => {
                                                    const country = countries.find((c) => c.value === item.country)

                                                    return (
                                                        <tr>
                                                            <td>{i + 1}</td>
                                                            <td>{item.companyName}</td>
                                                            <td>{item.localLanguageCompanyName}</td>
                                                            <td>{item.foundingDate}</td>
                                                            <td>{country?.label}</td>
                                                            <td>{item.percentage}%</td>
                                                            <td>
                                                                <Button onClick={() => {
                                                                    setSelectedShareholderToEdit({
                                                                        ...item,
                                                                        index : i
                                                                    })
                                                                }} outline color="primary" size="md" className="border-0">
                                                                    <i className="ri-pencil-line font-size-20"></i>
                                                                </Button>

                                                                <Button onClick={() => {
                                                                    setShareholders((currentHolders) => {
                                                                        const newList = [...currentHolders['company']];
                                                                        newList.splice(i, 1);

                                                                        return {
                                                                            ...currentHolders,
                                                                            'company' : newList
                                                                        }
                                                                    })
                                                                }} outline color="danger" size="md" className="border-0">
                                                                    <i className="ri-delete-bin-line font-size-20"></i>
                                                                </Button>
                                                            </td>
                                                        </tr>
                                                    )
                                                })}
                                            </tbody>
                                        </table>     
                                    </div>
                                </Col>
                            </Row>

                            <Row className="mt-3">
                                <Col sm="12" style={{ display: "flex", justifyContent: "flex-end" }}>
                                    <Button type="button" 
                                        color="primary" 
                                        outline
                                        onClick={() => setShowShareholderModal(true)}>
                                        {" + " + t("Add Shareholder")}
                                    </Button>
                                </Col>
                            </Row>
                        </div>
                    </CardBody>
                </Card>

                <div className="d-flex justify-content-end align-items-center mb-4">
                    <Button color="primary" 
                        className="tpdd-btn" 
                        type="submit"
                        disabled={ isFormSubmiting }>
                            {
                                isFormSubmiting && (
                                    <Spinner className="me-2" animation="border" variant="white" size="sm"/>
                                )
                            }
                        {t('Next')}
                    </Button>
                </div>
            </AvForm>
        </React.Fragment>
    )
};

const mapStatetoProps = (state) => {
	const { Organization } = state;

	return {
		Organization
	};
};

export default withNamespaces()(
    connect(mapStatetoProps, null)(
        CompanyDetails
    )
);