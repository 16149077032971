import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { withNamespaces } from "react-i18next";
import { connect } from "react-redux";
import { useMutation, useQuery } from "@tanstack/react-query";
import { Button, Col, Row, Modal, ModalHeader, ModalBody } from "reactstrap";
import { toast } from "react-toastify";
import Spinner from 'react-bootstrap/Spinner';
import AIGetHelpIcon from "src/modules/3rd-party-management/assets/images/microchip/white.png";
import AIBotty from "src/modules/3rd-party-management/assets/images/bottyAi-2.png";
import FindingsService from "src/modules/3rd-party-management/apis/FindingsService";
import SupplierService from "src/modules/3rd-party-management/apis/SupplierService";

import ModerateIcon from "src/modules/3rd-party-management/assets/images/ai-scoring/Moderate.svg";
import WeakIcon from "src/modules/3rd-party-management/assets/images/ai-scoring/Weak.svg";
import StrongIcon from "src/modules/3rd-party-management/assets/images/ai-scoring/Strong.svg";

import AIRiskAssessmentModal from "src/modules/3rd-party-management/components/RisksAndTasks/risks/ai/AssessmentModal";

import {
    CHAT_BOT_DOMAIN,
    API_URL_RISK_AI_GET_ADDITIONAL_DETAILS,
    API_BASE_URL
} from 'src/modules/3rd-party-management/constants';

const FindingDetails = function (props) {
    const {
        lng,
        organization,
        t,
        supplierId,
        finding : {
            id
        }
    } = props;

    const history = useHistory();

    const [ aiBasedRiskAssessmentModalStatus, setAiBasedRiskAssessmentModalStatus ] = useState(false);

    const [ aiScoreClass, setAiScoreClass ] = useState(null);

    const {
        data        :   finding,
        isLoading   :   findingDetailsIsLoading,
        isFetching  :   findingDetailsIsFetching
    } = useQuery({
		queryKey: ['3rd-party-management-fetch-finding-details'],
		queryFn: async () => {
			const service = FindingsService.getInstance();

            return await service.fetchDetails(supplierId, id);
		},
		cacheTime: 0,
		refetchOnWindowFocus: false,
		onError: (error) => {
			toast(t('An error occurred while fetching finding details.'), {
				type: 'error',
			});
		},
	});

    const {
        data        :   supplierInfos,
        isFetching  :   basicInfosIsFetching,
        isLoading   :   basicInfosIsLoading
    } = useQuery({
		queryKey: ['3rd-party-management-supplier-details-basic-infos'],
		queryFn: async () => {
			const service = SupplierService.getInstance();

            return await service.fetchBasicInfos(supplierId, {});
		},
		cacheTime: 0,
		refetchOnWindowFocus: false,
		onError: (error) => {
			toast(t('An error occurred while fetching supplier basic information.'), {
				type: 'error',
			});
		},
	});

    const handleToggleFlagStatusMutation = useMutation({
        mutationFn: async (payload) => {
            const service = FindingsService.getInstance();
    
            return await service.toggleFlag(payload);
        },
        onSuccess: () => {
            finding.flag = !finding.flag;

            toast(t("Flag status updated successfully."), {
                type: "success",
            });
        },
        onError: () => {
            toast(t("An error occurred while updating flag status."), {
                type: "error",
            });
        }
    });

    const handleFetchFindingScoreQuery = useQuery({
		queryKey: ['3rd-party-management-supplier-finding-ai-score-query'],
		queryFn: async () => {
			const service = FindingsService.getInstance();

            return await service.getAIScore({
                promt : `${finding.news_agency}, ${finding.about_url}`
            });
		},
		cacheTime: 0,
        enabled: false,
		refetchOnWindowFocus: false,
		onError: (error) => {
			toast(t('An error occurred while fetching ai score.'), {
				type: 'error',
			});
		},
	});

    const renderLoadingPlaceholder = () => {
        return (
            <Row>
                <Col sm="12" md="9">
                    <div className="dt-field dt-skeleton mb-4" style={{width: '70%'}}></div>
                    <div className="dt-field dt-skeleton mb-5" style={{width: '50%', height: '270px'}}></div>

                    <div className="dt-field dt-skeleton mb-1" style={{width: '90%'}}></div>
                    <div className="dt-field dt-skeleton mb-1" style={{width: '85%'}}></div>
                    <div className="dt-field dt-skeleton mb-1" style={{width: '90%'}}></div>
                    <div className="dt-field dt-skeleton mb-4" style={{width: '50%'}}></div>

                    <div className="dt-field dt-skeleton mb-1" style={{width: '90%'}}></div>
                    <div className="dt-field dt-skeleton mb-1" style={{width: '85%'}}></div>
                    <div className="dt-field dt-skeleton mb-1" style={{width: '90%'}}></div>
                    <div className="dt-field dt-skeleton mb-4" style={{width: '50%'}}></div>

                    <div className="dt-field dt-skeleton mb-1" style={{width: '90%'}}></div>
                    <div className="dt-field dt-skeleton mb-1" style={{width: '85%'}}></div>
                    <div className="dt-field dt-skeleton mb-1" style={{width: '90%'}}></div>
                    <div className="dt-field dt-skeleton" style={{width: '50%'}}></div>
                </Col>

                <Col sm="12" md="3">
                    <div className="dt-field dt-skeleton w-100 mb-4" style={{height: '270px'}}></div>
                    <div className="dt-field dt-skeleton w-100" style={{height: '320px'}}></div>
                </Col>
            </Row>
        );
    }

    const renderModals = () => {
        return (
            aiBasedRiskAssessmentModalStatus && (
                <AIRiskAssessmentModal
                    closeModal={ () => setAiBasedRiskAssessmentModalStatus(false) }
                    onRisksCreated={ () => history.push({ search : '?tab=risk-assessment'}) }
                    isOpen={ aiBasedRiskAssessmentModalStatus }
                    translateResult={ true }
                    aiRequestOptions={{
                        requestUrl      :   `${CHAT_BOT_DOMAIN}api/tpdd/risks-associated.ai`,
                        payload         :   {
                            findingId           :   id,
                            productsGroup       :   (supplierInfos?.product || []).map((product) => {
                                return product?.name.productGroup;
                            }),
                            organizationId      :   organization.id,
                            myLocation          :   organization?.countryId,
                            targetCountry       :   (supplierInfos?.locations || []).filter((location) => location.type === 'office')[0]?.country
                        }
                    }}
                    aiTranlateRequestOptions={{
                        requestUrl      :   `${CHAT_BOT_DOMAIN}api/tpdd/risks-associated-translate.ai`,
                        payload         :   {
                            findingId           :   id,
                            organizationId      :   organization.id,
                            module              :   "TPDD_NEWS"
                        }
                    }}
                    additionnalDetailsRequestOptions={{
                        requestUrl  :   API_URL_RISK_AI_GET_ADDITIONAL_DETAILS,
                        payload     :   {}
                    }}
                    createRisksRequestOptions={{
                        requestUrl      :   `${API_BASE_URL}/risk_rate/create_ai`,
                        payload         :   {
                            supplier    :   supplierId,
                            news        :   id
                        }
                    }}>
                    {(result) => {
                        return (
                            <Modal className="ai-risk-result-modal mw-100" style={{width: '90%'}} isOpen={aiBasedRiskAssessmentModalStatus} size="xl">
                                <ModalHeader toggle={() => setAiBasedRiskAssessmentModalStatus(!aiBasedRiskAssessmentModalStatus)}>
                                    {t('Risk Assesment Support by AI')}
                                </ModalHeader>
                                <ModalBody>
                                    <result.type {...result.props} />
                                </ModalBody>
                            </Modal>
                        )
                    }}
                </AIRiskAssessmentModal>
            )
        )
    }

    const renderAiScoreClassImage = () => {
        let resultImage;

        const scoreClass = (aiScoreClass || '').toString().toLowerCase();

        if(scoreClass === 'class a'){
            resultImage = WeakIcon;
        }
        else if(scoreClass === 'class b'){
            resultImage = ModerateIcon;
        }
        else{
            resultImage = StrongIcon;
        }

        return (
            <img src={ resultImage } alt="" />
        );
    }

    const renderAiScoreClassBox = () => {
        if(handleFetchFindingScoreQuery.isLoading || handleFetchFindingScoreQuery.isFetching || !aiScoreClass){
            return (
                <Col sm="12">
                    <div className="dt-field dt-skeleton w-100" style={{height: '320px'}}></div>    
                </Col>
            );
        }
        
        return (
            <div className="side-box diss-co-score">
                <div className="scoring">
                    { renderAiScoreClassImage() }
                </div>
            </div>
        );
    }

    useEffect(() => {
        if(finding){
            handleFetchFindingScoreQuery.refetch();
        }
    }, [finding])

    useEffect(() => {
        if(handleFetchFindingScoreQuery.data && handleFetchFindingScoreQuery.data.out){
            setAiScoreClass(handleFetchFindingScoreQuery.data.out);
        }
    }, [ handleFetchFindingScoreQuery.data ]);
    
    const isLoading = basicInfosIsFetching || basicInfosIsLoading || findingDetailsIsLoading || findingDetailsIsFetching;

    return (
        <div className="finding-details-content p-2">
            {
                (isLoading) ? (
                    <>{ renderLoadingPlaceholder() }</>
                ) : (
                    <Row>
                        <Col sm="12" md="9">
                            <div className="finding-content">
                                <div className="finding-content-title">
                                    <h5>
                                        <i className="ri-checkbox-blank-circle-fill text-danger me-2"></i>
                                        {t(finding.title)}
                                    </h5>
                                </div>
                                
                                <div className="finding-actions-container mt-4">
                                    {lng !== 'en' && (
                                        <Button outline color="primary">
                                            { t('Translate To English') }
                                        </Button>
                                    )}
                                </div>

                                <div className="finding-content-image mt-3 mb-4">
                                    <img src={`/images/findings/${finding.image}.jpg`} alt="finding" />
                                </div>

                                <div className="finding-content-body mb-4">
                                    { finding.body }
                                </div>

                                <div className="finding-source-link mb-4">
                                    <a href={finding.source_link} rel="noreferrer" target="_blank">
                                        { finding.source_link }
                                    </a>
                                </div>

                                <div className="finding-actions-container">
                                    <Button disabled={ handleToggleFlagStatusMutation.isLoading } color="primary" className="tpdd-btn" onClick={() => handleToggleFlagStatusMutation.mutate({
                                        newsId : finding.id
                                    })}>
                                        {
                                            handleToggleFlagStatusMutation.isLoading ? (
                                                <Spinner animation="border" variant="white" size="sm"/>
                                            ) : (
                                                <>{ t(finding.flag ? 'Unflag' : 'Flag') }</>
                                            )
                                        }
                                    </Button>
                                </div>
                            </div>
                        </Col>

                        <Col sm="12" md="3">
                            <div className="side-box ai-help-box mb-4">
                                <h5>
                                    { t('Risk Assessment Support by AI') }
                                </h5>

                                <div className="image-box">
                                    <img src={AIBotty} alt="Risk Assessment Support by AI" />
                                </div>

                                <Button onClick={() => {
                                    setAiBasedRiskAssessmentModalStatus(true)
                                }} className="d-flex justify-content-center w-100" color="primary">
                                    <img className="me-2" src={AIGetHelpIcon} alt="" />
                                    { t('Get Help From AI') }
                                </Button>
                            </div>

                            { renderAiScoreClassBox() }
                        </Col>

                        { renderModals() }
                    </Row>
                )
            }
        </div>
    )
};

const mapStatetoProps = (state) => {
    const { Organization } = state;
    const { token } = state.Login;

    return {
        user            :   state.Login.user,
        organization    :   Organization,
        token
    };
};

export default withNamespaces()(
    connect(
        mapStatetoProps,
        {}
    )(FindingDetails)
);