import { withNamespaces } from "react-i18next";
import { useEffect, useState } from "react";
import { withRouter, useHistory, Link } from "react-router-dom";
import { connect } from "react-redux";
import { errorNotification, logoutUser } from "src/store/actions";
import { Container, Row, Col, Spinner } from "reactstrap";

import LanguageDropdown from "src/components/CommonForBoth/TopbarDropdown/LanguageDropdown";
import NotificationDropdown from 'src/components/CommonForBoth/TopbarDropdown/NotificationDropdown/NotificationDropdown';
import ProfileMenu from 'src/components/CommonForBoth/TopbarDropdown/ProfileMenu';

import feedback from "src/assets/images/feedback.png";
import balance from "src/assets/images/balance.png";
import secondCanvas from "src/assets/images/canva2.gif";
import logo from "src/assets/images/Logo-new.png";
import DataSecurity from "src/assets/images/Data Security.png";
import CustomerSupport from "src/assets/images/Support.png";
import Contact from "src/assets/images/Contact.png";
import SecurityFAQ from "src/assets/pdf/DISS-CO_SIP_Data Protection and Security FAQ_20240208.pdf";
import ISOCertificate from "src/assets/pdf/ISO27001_DISS-CO_44 121 222498 englisch.pdf";
import TOM from "src/assets/pdf/DISS-CO_TOM_20230820.pdf";
import PenTest from "src/assets/pdf/Pen Test-letter-attestation_DISS-CO_20231006.pdf";
import { saveOrganizationActiveModuleOnRemote } from "../../../store/actions";
import UserUtils from "src/services/utils/UserUtils";

import "./style.scss";

const modulesData = {
    wb : {
        title   :   'Whistleblowing',
        desc    :   '',
        icon    :   balance
    },
    cm : {
        title   :   'Complaint',
        desc    :   '',
        icon    :   feedback
    },
    gd : {
        title   : 'GDPR',
        desc    : ''
    },
    tp : {
        title   : 'TPDD',
        desc    : ''
    }
}

const ModuleSelection = ({
    t,
    user,
    organization,
    modules,
    saveOrgModuleOnRemote
}) => {
    const [ isModuleSelectionLoading, setIsModuleSelectionLoading ] = useState(false);
    const [moduleList, setModuleList] = useState([]);

    const history = useHistory();
    const userUtils = new UserUtils();

    const handleRotateModules = (direction) => {
        if (moduleList.length > 1) {
            const newModules = [...moduleList];
            if (direction === 'prev') {
                const lastModule = newModules.pop();
                newModules.unshift(lastModule);
            } else if (direction === 'next') {
                const firstModule = newModules.shift();
                newModules.push(firstModule);
            }
            setModuleList(newModules);
        }
    };

    let isDesktop = false;

    if(window.innerWidth > 768) {
        isDesktop = true;
    }

    useEffect(() => {
        if (modules && modules?.allModules) {
            const filteredModules = modules?.allModules.filter(module => {
                // Exclude modules where !modulesData[module.domain] is true
                return modulesData[module?.domain];
            });
            setModuleList(filteredModules);
        }
    }, []);

  
    const handleRenderModuleBox = (module) => {
        if(!modulesData[module.domain]) return null;

        return (
            <Link to={module.hasModule ? userUtils.getUserHomeRoute(user, module.domain) : '#'} className="d-flex mt-5 option"
                key={module.domain}
                onClick={() => module.hasModule && saveOrgModuleOnRemote(module.domain)}>
                <div>
                    <div className="circle ml-4">
                        <div className="second-circle">
                            <p className="mb-0">
                                {
                                    isModuleSelectionLoading ? (
                                        <Spinner className="me-2" animation="border" variant="danger" size="sm" type="grow"/>
                                    ) : (
                                        <>{modulesData[module.domain]?.title}</>
                                    )
                                }
                            </p>
                        </div>
                    </div>
                </div>{" "}
            </Link>
        );
    }

    const handleNewRenderModuleBox = (module, isCenter) => {
        if (!modulesData[module.domain]) return null;

        return (
            <Link
                to={module.hasModule ? userUtils.getUserHomeRoute(user, module.domain) : '#'}
                className={`d-flex ${isCenter ? 'carousel-center' : 'carousel-item-side'}`}
                onClick={() => module?.hasModule && saveOrgModuleOnRemote(module.domain)}
                key={module.id}
            >
                <div className="circle-box">
                    <div className="circle-content">
                        {isModuleSelectionLoading ? (
                            <Spinner className="me-2" animation="border" variant="danger" size="sm" type="grow" />
                        ) : (
                            <>{modulesData[module.domain]?.title}</>
                        )}
                    </div>
                </div>
            </Link>
          );
        }



    const renderThirdPartyView = () => {
        return isDesktop ? (
          <Container fluid>
            <div className="start-page-third-party">
              <p className="start-page-title">
                {t("What would you like to report?")}
              </p>
              <div className="options">
                  <>
                    {(modules.allModules || []).map((module) => {
                      return handleRenderModuleBox(module);
                    })}
                  </>
              </div>
            </div>
          </Container>
        ) : (
          <Container fluid>
            <div className="start-page-third-party">
              <p className="start-page-title">
                {t("What would you like to report?")}
              </p>

              <Row className="options" style={{ margin: "0 1rem" }}>
                {(modules.allModules || []).map((module) => {
                  return (
                    <Col key={module.name} xs={12} md={6}>
                      {handleRenderModuleBox(module)}
                    </Col>
                  );
                })}
              </Row>
            </div>
          </Container>
        );
    }

   

    const renderAdminView = () => {
        return (
          <div className="pt-sm-5 background">
            <Container className="start-page-admin">
              <Row className="logo-container">
                <Col lg={8}>
                  <Row>
                    <Col lg={11} className="logos">
                      <img
                        src={logo}
                        className="logo diss-co-logo"
                        alt="logo"
                      />
                      {organization?.secondaryLogo ? (
                        <img
                          src={organization.secondaryLogo}
                          className="logo"
                          alt="logo"
                        />
                      ) : (
                        <p
                          className="ms-4 pt-3"
                          style={{ color: "#fff", fontSize: "2em" }}
                        >
                          {t("Logo")}
                        </p>
                      )}
                    </Col>
                  </Row>
                </Col>
                <Col
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center",
                  }}
                >
                  <LanguageDropdown />
                  <NotificationDropdown />
                  <ProfileMenu />
                </Col>
              </Row>

              <Row className="section-one row d-flex flex-row-reverse justify-content-between">
                <Col xs={5} className="d-flex justify-content-end relative">
                  <div className="gif-container">
                    <img
                      style={{
                        width: "200px",
                        transform: "rotate(120deg)",
                        top: 0,
                      }}
                      alt="section img"
                      src={secondCanvas}
                    />
                  </div>
                </Col>
                <Col xs={7}>
                  <div>
                    <p className="title">
                      {t("Welcome To The")} <br />{" "}
                      {t("Smart Integrity Platform")}
                    </p>

                    <p className="sub-title">{t("Access to your modules!")}</p>
                  </div>
                  <div className="d-flex mt-5 options">
                    {!isDesktop ? (
                      <>
                        {(modules.allModules || []).map((module) => {
                          return handleRenderModuleBox(module);
                        })}
                      </>
                    ) : (
                      <>
                        <div className="carousel-container mt-3">
                          <button
                            className="carousel-button prev"
                            onClick={() => handleRotateModules("prev")}
                          >
                            ❮
                          </button>
                          <div className="carousel-items">
                            {moduleList.map((module, index) => {
                              const isCenter =
                                index === Math.floor(moduleList.length / 2);
                              return handleNewRenderModuleBox(module, isCenter);
                            })}
                          </div>
                          <button
                            className="carousel-button next"
                            onClick={() => handleRotateModules("next")}
                          >
                            ❯
                          </button>
                        </div>
                      </>
                    )}
                  </div>
                </Col>
              </Row>

              <Row className="justify-content-between mt-10 align-items-center thirt-section">
                <Col xs={6} className="d-flex flex-column justify-content-end">
                  <p className="title"></p>
                  <p className="sub-title" style={{ maxWidth: "80%" }}>
                    {t(
                      "Please download here the latest security documents. We ensure the safety and security of your data."
                    )}
                  </p>

                  <Row>
                    <Col sx={6} lg={4}>
                      <a
                        className="button d-flex align-items-center justify-content-center w-100 px-2 text-center"
                        href={SecurityFAQ}
                        download="Security-FAQ-PDF-document"
                        target="_blank"
                        rel="noreferrer"
                      >
                        {t("Security FAQ")}
                      </a>
                      <a
                        className="button d-flex align-items-center justify-content-center w-100 px-2 text-center"
                        href="https://www.hetzner.com/de/unternehmen/zertifizierung"
                        download="ISO-Certificate-PDF-document"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <span>
                          {t("Data Center")} <br></br> {t("ISO 27001")}
                        </span>
                      </a>
                    </Col>

                    <Col sx={6} lg={4}>
                      <a
                        className="button d-flex align-items-center justify-content-center w-100 px-2 text-center"
                        href={ISOCertificate}
                        download="ISO-Certificate-PDF-document"
                        target="_blank"
                        rel="noreferrer"
                      >
                        {t("ISO 27001")}
                      </a>
                      <a
                        className="button d-flex align-items-center justify-content-center w-100 px-2 text-center"
                        href="https://www.hetzner.com/assets/downloads/SoA.pdf"
                        download="Data-Center-SoA-document"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <span>{t("Data Center SoA")}</span>
                      </a>
                    </Col>

                    <Col sx={6} lg={4}>
                      <a
                        className="button d-flex align-items-center justify-content-center w-100 px-2 text-center"
                        href={PenTest}
                        download="PEN-TEST-PDF-document"
                        target="_blank"
                        rel="noreferrer"
                      >
                        {t("Pen Test")}
                      </a>
                      <a
                        className="button d-flex align-items-center justify-content-center w-100 px-2 text-center"
                        href={TOM}
                        download="TOM-PDF-document"
                        target="_blank"
                        rel="noreferrer"
                      >
                        {t("TOM")}
                      </a>
                    </Col>
                  </Row>
                </Col>
                <Col sx={6}>
                  <img
                    className="fifth-canva ms-5 ps-5"
                    src={DataSecurity}
                    alt="section img"
                  />
                </Col>
              </Row>

              <Row className="justify-content-between mt-10 align-items-center thirt-section">
                <Col xs={6} className="d-flex flex-column justify-content-end">
                  <p className="title"></p>
                  <p className="sub-title" style={{ maxWidth: "81%" }}>
                    {t(
                      "We understand that technology issues can be frustrating, but you're in good hands. Our team of dedicated technical experts is here to assist you in resolving any tech-related challenges you may encounter."
                    )}
                  </p>
                  <a
                    className="button d-flex align-items-center justify-content-center"
                    href="mailto: support@diss-co.tech"
                  >
                    {t("Contact Support")}
                  </a>
                </Col>
                <Col sx={6}>
                  <img
                    className="ms-5 ps-5"
                    src={CustomerSupport}
                    alt="section img"
                  />
                </Col>
              </Row>

              <Row className="justify-content-between align-items-center thirt-section">
                <Col xs={8} className="d-flex flex-column justify-content-end">
                  <p className="title">{t("Contact Us")}</p>
                  <p className="sub-title fw-700">
                    {t(
                      "DISS-CO® GmbH (Digital Solutions & Services for Compliance)"
                    )}
                  </p>
                  <p className="fw-700 fs-18">
                    {t("Strandbaddamm 4")} <br /> {t("22880 Wedel")} <br />
                    Germany
                  </p>

                  <p className="fw-700 fs-28 m-0">{t("Phone")}</p>
                  <p className="fs-18">+49 (0)40-226 392 51-0</p>
                  <p className="fw-700 fs-28 m-0">{t("Email")}</p>
                  <p className="fs-18">info@diss-co.tech</p>
                  <p className="fw-700 fs-28 m-0">{t("Technical Support")}</p>
                  <p className="fs-18">support@diss-co.tech</p>
                  <a
                    className="fs-20"
                    href="https://diss-co.tech/terms-and-conditions/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    {t("General Terms and Conditions")}
                  </a>
                  <a
                    className="fs-20"
                    href="https://diss-co.tech/terms-and-conditions/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    {t("Data Processing Agreement")}
                  </a>
                  <a
                    className="fs-20"
                    href="https://diss-co.tech/privacy-policy/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    {t("Privacy Policies")}
                  </a>
                  <p className="fw-700 sub-title mt-5"> </p>
                  <div className="d-flex">
                    <a
                      className="follow-links"
                      href="https://www.linkedin.com/company/diss-co"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Linkedin
                    </a>
                    <a
                      className="follow-links"
                      href="https://www.xing.com/pages/diss-co"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Xing
                    </a>
                    <a
                      className="follow-links"
                      href="https://www.youtube.com/channel/UCH65RaR2Kbn_-djmrIlE-xQ"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Youtube
                    </a>
                    <a
                      className="follow-links"
                      href="https://www.facebook.com/profile.php?id=100089887982972"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Facebook
                    </a>
                  </div>
                </Col>
                <Col sx={4}>
                  <div className="second-section">
                    <img
                      src={Contact}
                      className=""
                      style={{ width: "80%", height: "80%" }}
                      alt="section img"
                    />
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        );
    }

    useEffect(() => {
        if (modules.list.length === 1) {
            saveOrgModuleOnRemote(modules.list[0].name);
        }
    }, []);

    return renderAdminView()
};

const mapStatetoProps = (state) => {
    return {
        user: state.Login.user,
        token: state.Login.token,
        organization: state.Organization,
        modules : state.Modules
    };
};

export default withNamespaces()(
    withRouter(connect(mapStatetoProps, {
        logoutUser,
        errorNotification,
        saveOrgModuleOnRemote  : (module) => saveOrganizationActiveModuleOnRemote(module)
    })(ModuleSelection))
);
  