import {
    ROUTE_PATH_THIRD_PARTY_REPORT_LIST,
    ROUTE_PATH_THIRDPARTY_FILL_QUESTIONNAIRE
} from "src/modules/3rd-party-management/constants";

import {
    ROUTE_PATH_THIRD_PARTY_HOME
} from "src/common/constants";

const ThirdPartyMenuItems = [
    {
        titleKey    :   "Home",
        linkType    :   "react-router",
        path        :   ROUTE_PATH_THIRD_PARTY_HOME,
        icon        :   "ri-home-4-line",
        authProtected   :   false,
        settingKey      :   null,
        moduleFeatures  :   [],
        roles           :   [],
        customValidator :   null
    },
    {
        titleKey    :   "My reports",
        linkType    :   "react-router",
        path        :   ROUTE_PATH_THIRD_PARTY_REPORT_LIST,
        icon        :   "ri-mail-line",
        authProtected   :   true,
        settingKey      :   null,
        moduleFeatures  :   [],
        roles           :   [],
        customValidator :   null
    },
    {
        titleKey    :   "Submit A New Report",
        linkType    :   "react-router",
        path        :   ROUTE_PATH_THIRDPARTY_FILL_QUESTIONNAIRE,
        icon        :   "ri-book-open-line",
        moduleFeatures  :   [],
        authProtected   :   true,
        settingKey      :   null,
        roles           :   [],
        customValidator :   null
    }
];

export default ThirdPartyMenuItems;