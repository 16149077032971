import {    
    ORGANIZATION_MODULES_LOADED_SUCCESSFULLY,
    SET_ORGANIZATION_ACTIVE_MODULE,
    ORGANIZATION_DOEST_HAVE_ACTIVE_MODULE_ERROR,
    ACTIVE_MODULE_FEATURES_LOADED_SUCCESSFULLY
} from "./actionTypes";

const initialState = {
    allModules  :   [],
    list        :   [], //refers to organization activated modules
    active      :   localStorage.getItem('module') || null
};

export const Modules = (state = initialState, action) => {
    switch (action.type) {
        case ORGANIZATION_MODULES_LOADED_SUCCESSFULLY:
            return {
                ...state,
                allModules  :   action.payload.allModules,
                list        :   action.payload.organizationModules
            }
        case SET_ORGANIZATION_ACTIVE_MODULE:
            return {
                ...state,
                active  :   action.payload
            }
        case ORGANIZATION_DOEST_HAVE_ACTIVE_MODULE_ERROR:
            return {
                ...state,
                active  :   -1
            }
        case ACTIVE_MODULE_FEATURES_LOADED_SUCCESSFULLY:
            const activeModuleIndex = state.allModules.findIndex((m) => m.domain === state.active);

            const newModules = [...state.allModules];

            if(activeModuleIndex > -1){
                newModules[activeModuleIndex].features = action.payload.features;
            }

            return {
                ...state,
                allModules: newModules
            }
        default:
            return {
                ...state
            }
    }
};