import React, { useEffect, useRef, useState } from "react";
import { 
    Container, 
    Row, 
    Col, 
    Card, 
    CardBody, 
    Button, 
    Label, 
    ModalFooter, 
    Modal, 
    ModalHeader, 
    ModalBody, 
    Alert
} from "reactstrap";
import Spinner from 'react-bootstrap/Spinner'
import { useQuery, useMutation } from '@tanstack/react-query'
import axios from "axios";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { withNamespaces } from "react-i18next";
import {
  AvForm,
  AvField,
  AvInput,
} from "availity-reactstrap-validation";
import InputPhoneNumber from "../../components/Fields/InputPhoneNumber";
import {
  API_URL_ADMIN_UPDATE_USER_MANAGEMENT,
  API_URL_GET_USER_DETAIL,
  API_URL_GET_NOTIFICATION_LIST,
  API_URL_UPDATE_NOTIFICATION_LIST
} from "../../common/constants";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { toast } from 'react-toastify';
import { authCurrentUser } from "../../store/auth/login/actions";
import UserUtils from "../../services/utils/UserUtils";

const Profile = ({
    t,
    token,

    authCurrentUser
}) => {
    const userUtils = new UserUtils();

    const [userData, setUserData] = useState(null);
    const [showPrivateApprovalModal, setShowPrivateApprovalModal] = useState(false);
    const [avatar, setAvatar] = useState(null);
    const [removeAvatar, setRemoveAvatar] = useState(false);
    const avatarFileRef = useRef();
    const profileFormRef = useRef();

    const { 
        isFetching: notificationsSettingIsLoading, 
        refetch: refetchNotificationsSettings, 
        data: notificationsOptions = [],
        dataUpdatedAt: notificationsSettingsUpdatedAt
    } = useQuery({
        queryKey: ['fetch-notifications-settings'],
        queryFn: async () => {
            const response = await axios.get(
                API_URL_GET_NOTIFICATION_LIST,
                {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                }
            )
    
            return response.data?.data || []
        },
        cacheTime: 0,
        enabled: false,
        refetchOnWindowFocus: false,
        onError: (_error) => {
            toast(t('An error occurred while fetching user data.'), {
                type: 'error'
            })
        }
    })

    const { 
        isFetching: userDataIsLoading,
        refetch: refetchUserData
    } = useQuery({
        queryKey: ['fetch-user-data-query'],
        queryFn: async () => {
            const response = await axios.get(
                API_URL_GET_USER_DETAIL,
                {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                }
            )
    
            return response.data?.data || {}
        },
        cacheTime: 0,
        refetchOnWindowFocus: false,
        onError: (_error) => {
            toast(t('An error occurred while fetching user data.'), {
                type: 'error'
            })
        },
        onSuccess: (data) => {
            setUserData(data);

            setAvatar(data.avatar || null);

            if(userUtils.isAnalystAdmin(data) && !notificationsSettingsUpdatedAt){
                refetchNotificationsSettings();
            }
        }
    })

    const {
        mutate: updateProfileMutate,
        isLoading: profileMutationIsLoading
    } = useMutation({
        mutationFn: async (payload) => {
            const response = await axios.post(API_URL_ADMIN_UPDATE_USER_MANAGEMENT, payload, {
                headers: {
                    "Content-Type": "multipart/form-data",
                    "Authorization": `Bearer ${token}`,
                },
            })
    
            return response.data?.data || {}
        },
        onError: (error) => {
            toast(t('Some error happened while updating profile'), {
                type: 'error'
            });
        },
        onSuccess: (data, sentPayload) => {
            toast(t('Data was saved successfully'), {
                type: 'success'
            });

            setUserData({
                ...userData,
                first_name: sentPayload.first_name,
                last_name: sentPayload.last_name
            });

            authCurrentUser();
        }
    })

    const {
        mutate: updateNotificationSettingsMutate,
        isLoading: notificationSettingsMutationIsLoading
    } = useMutation({
        mutationFn: async (payload) => {
            const response = await axios.post(API_URL_UPDATE_NOTIFICATION_LIST, payload, {
                headers: {
                    "Authorization": `Bearer ${token}`,
                },
            })
    
            return response.data?.data || {}
        },
        onError: () => {
            toast(t('An error occurred while updating notifications setting'), {
                type: 'error'
            });
        },
        onSuccess: () => {
            toast(t('Data was saved successfully'), {
                type: 'success'
            });
        }
    })

    useEffect(() => {
        if(avatar){
            setRemoveAvatar(false);
        }
    }, [avatar])

    const handleProfileFormSubmit = () => {
        const values = profileFormRef.current.getValues();

        values.user_id = userData.id;

        values.avatar = (removeAvatar || !avatar || typeof avatar === 'string') ? undefined : avatar;

        values.remove_avatar = removeAvatar || undefined;

        values.phone_number = userData.phone_number || "";

        updateProfileMutate(values);
    }

    const handleNotificationsSettingsSubmit = (e, values) => {
        const enabledItems = [];

        for(const item in values){
            if(values[item]){
                enabledItems.push(item);
            }
        }

        updateNotificationSettingsMutate({
            enableNotifications :   enabledItems
        });
    }

    const renderAvatarImage = () => {
        return (
            <>
                {avatar ? (
                    <img src={typeof avatar === 'string' ? avatar : URL.createObjectURL(avatar)} alt="avatar" />
                ) : (
                    <span>{t('Add Photo')}</span>
                )}
            </>
        )
    }

    const renderNotificationsSettings = () => {
        return (
            <div className="notifications-settings">
                <h4 className="settings-title mb-3">{t('Notifications Setting')}</h4>
                <div className="notifications-settings-group mb-3">
                    <h5 className="group-title mb-3">{t('Email Notifications')}</h5>
                    <div className="group-items">
                        {notificationsSettingIsLoading ? (
                            <>
                                <div className="loading-item">
                                    <div>
                                        <div className='dt-field dt-skeleton dt-select-list' />
                                    </div>
                                    <div>
                                        <div className='dt-field dt-skeleton dt-select-list' />
                                    </div>
                                </div>

                                <div className="loading-item">
                                    <div>
                                        <div className='dt-field dt-skeleton dt-select-list' />
                                    </div>
                                    <div>
                                        <div className='dt-field dt-skeleton dt-select-list' />
                                    </div>
                                </div>

                                <div className="loading-item">
                                    <div>
                                        <div className='dt-field dt-skeleton dt-select-list' />
                                    </div>
                                    <div>
                                        <div className='dt-field dt-skeleton dt-select-list' />
                                    </div>
                                </div>
                            </>
                        ) : (
                            <>
                                {notificationsOptions.map((notificationOption, index) => {
                                    return (
                                        <div key={index} className="form-check form-switch mb-3">
                                            <AvInput defaultValue={notificationOption.active} id={`notification-option-${index}`} type="checkbox" name={notificationOption.value} />
                                            <Label htmlFor={`notification-option-${index}`} className="form-check-label">
                                                {t(notificationOption.label)}
                                            </Label>
                                        </div> 
                                    )
                                })}
                            </>
                        )}
                    </div>
                </div>
            </div>
        )
    }

    const renderProfileForm = () => {
        const userIsThirdParty = userData && userUtils.isThirdParty(userData);
        const userIsEmployee = userData && userUtils.isEmployee(userData);
        const userIsAnalystAdmin = userData && userUtils.isAnalystAdmin(userData);

        return (
            <Card>
                <CardBody className="p-5">                    
                    <AvForm ref={profileFormRef} className='needs-validation' onValidSubmit={(e, values) => {
                        if(userIsEmployee && !values.private_email){
                            setShowPrivateApprovalModal(true);
                            return;
                        }

                        handleProfileFormSubmit()
                    }}>
                        <Row className="mb-4">
                            <Col sm="12">
                                <div className="account-avatar-updator">
                                    <div className="avatar-preview">
                                        {userDataIsLoading ? (
                                            <div className='dt-field dt-skeleton dt-select-list' style={{
                                                height: '100%'
                                            }} />
                                        ) : (
                                            <>{ renderAvatarImage() }</>
                                        )}
                                    </div>

                                    <div className="actions-container">
                                        <span className="user-fullname">
                                            {userDataIsLoading ? (
                                                <div className='dt-field dt-skeleton dt-select-list' />
                                            ) : (
                                                <>{`${userData?.first_name || ''} ${userData?.last_name || ''}`}</>
                                            )}
                                        </span>

                                        <div className="actions">
                                            <Button size="sm" type="button" color="primary" outline className="change-avatar-btn"> 
                                                <AvInput
                                                    name="avatar"
                                                    type="file"
                                                    accept="image/png, image/jpeg"
                                                    ref={avatarFileRef}
                                                    onChange={(e) => {
                                                        setAvatar(e.target.files[0]);
                                                    }}
                                                />

                                                {t('Update Profile Picture')}
                                            </Button>

                                            {avatar && (
                                                <Button size="sm" color="link" type="button" onClick={() => {
                                                    avatarFileRef?.current && avatarFileRef.current.reset();
                                                    setAvatar(null);
                                                    setRemoveAvatar(true);
                                                }}>
                                                    <i className="ri-delete-bin-line me-1"></i>
                                                    {t('Remove')}
                                                </Button>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        </Row>

                        <Row className="mb-4">
                            <Col sm="12" md="12" lg="12" xl="9">
                                <Row>
                                    <Col sm="12" md="6" className="mb-3">
                                        <Label className="form-label" htmlFor="gender">
                                            {t("Title")}
                                        </Label>
                                        {userDataIsLoading ? (
                                            <div className='dt-field dt-skeleton dt-select-list' />
                                        ) : (
                                            <AvField id="gender" type="select" name="gender"
                                                errorMessage={t(
                                                    "This field cannot be blank"
                                                )}
                                                className="form-control"
                                                value={userData?.gender}
                                                defaultValue={"Mr."}
                                                validate={{ required: { value: true } }}>
                                                    <option value={"Mr."}>
                                                        {t("Mr.")}
                                                    </option>
                                                    <option value={"Mrs."}>
                                                        {t("Mrs.")}
                                                    </option>
                                            </AvField>
                                        )}
                                    </Col>

                                    <Col sm="12" md="3" className="mb-3">
                                        <Label className="form-label" htmlFor="first-name">
                                            {t("First name")}
                                        </Label>
                                        {userDataIsLoading ? (
                                            <div className='dt-field dt-skeleton dt-select-list' />
                                        ) : (
                                            <AvField
                                                name="first_name"
                                                placeholder=""
                                                type="text"
                                                errorMessage={t("Enter first name")}
                                                className="form-control"
                                                validate={{ required: { value: true } }}
                                                value={userData?.first_name}
                                                id="first-name"
                                            />
                                        )}
                                    </Col>

                                    <Col sm="12" md="3" className="mb-3">
                                        <Label className="form-label" htmlFor="lastname">
                                            {`${t("Last name")} ${t("(Optional)")}`}
                                        </Label>

                                        {userDataIsLoading ? (
                                            <div className='dt-field dt-skeleton dt-select-list' />
                                        ) : (
                                            <AvField name="last_name"
                                                placeholder=""
                                                type="text"
                                                errorMessage={t("Enter last name")}
                                                className="form-control"
                                                value={userData?.last_name}
                                                validate={{ required: { value: false } }}
                                                id="lastname"
                                            />
                                        )}
                                    </Col>
                                </Row>

                                <Row>
                                    {userIsThirdParty && (
                                        <Col sm="12" md="6" className="mb-3">
                                            <Label className="form-label" htmlFor="organization">
                                                {t("Organization")}
                                            </Label>

                                            {userDataIsLoading ? (
                                                <div className='dt-field dt-skeleton dt-select-list' />
                                            ) : (
                                                <AvField name="organization_name" placeholder=""
                                                    type="text"
                                                    errorMessage={t("Enter organization")}
                                                    className="form-control"
                                                    value={ userData?.third_party_organization }
                                                    validate={{ required: { value: false } }}
                                                    id="lastname"
                                                />
                                            )}
                                        </Col>
                                    )}

                                    <Col sm="12" md={userIsThirdParty ? '6' : '12'} className="mb-3">
                                        <Label className="form-label" htmlFor="company-position">
                                            {`${t("Company Position")} ${t("(Optional)")}`}{" "}
                                        </Label>

                                        {userDataIsLoading ? (
                                            <div className='dt-field dt-skeleton dt-select-list' />
                                        ) : (
                                            <AvField value={userData?.position}
                                                name="position"
                                                placeholder=""
                                                type="text"
                                                className="form-control"
                                                id="company-position"
                                            />
                                        )}
                                    </Col>
                                </Row>

                                <Row>
                                    <Col sm="12" md="6" className="mb-3">
                                        <Label className="form-label" htmlFor="email">
                                            {t("Email")}
                                        </Label>

                                        {userDataIsLoading ? (
                                            <div className='dt-field dt-skeleton dt-select-list' />
                                        ) : (
                                            <AvField disabled={!userIsThirdParty}
                                                name="email"
                                                placeholder=""
                                                type="text"
                                                errorMessage={t("Enter user email")}
                                                className="form-control"
                                                value={userData?.email}
                                                validate={{
                                                    required: { 
                                                        value:  !userIsThirdParty  
                                                    },
                                                    email: true
                                                }}
                                                id="email"
                                            />
                                        )}
                                    </Col>

                                    <Col sm="12" md="6" className="mb-3">
                                        <Label className="form-label" htmlFor="phone-number">
                                            {`${t("Phone number")} ${t("(Optional)")}`}
                                        </Label>
                                        {userDataIsLoading ? (
                                            <div className='dt-field dt-skeleton dt-select-list' />
                                        ) : (
                                            <InputPhoneNumber
                                                id="phone-number"
                                                name="phone_number"
                                                required={false}
                                                className="form-control"
                                                value={userData?.phone_number}
                                                validate={{ required: { value: false } }}
                                                onChange={(phone) => {
                                                    setUserData({
                                                        ...userData,
                                                        phone_number: phone
                                                    })
                                                }}
                                            />
                                        )}
                                        
                                    </Col>
                                </Row>

                                {userIsEmployee && (
                                    <Row>
                                        <Col sm="12" md="6" className="mb-3">
                                            <Label className="form-label" htmlFor="pEmail">
                                                {t("Private Email")}
                                            </Label>
                                            {userDataIsLoading ? (
                                                <div className='dt-field dt-skeleton dt-select-list' />
                                            ) : (
                                                <AvField name="private_email" placeholder="" type="text"
                                                    errorMessage={t(
                                                        "Enter user private email"
                                                    )}
                                                    className="form-control"
                                                    value={userData?.private_email}
                                                    validate={{
                                                        email: true,
                                                    }}
                                                    id="pEmail"
                                                />
                                            )}
                                        </Col>
                                    </Row>
                                )}
                            </Col>
                        </Row>

                        <Row>
                            <Col sm="12" className="d-flex justify-content-end">
                                <Button onClick={refetchUserData} color="primary" type="button" outline className="me-2" disabled={profileMutationIsLoading || userDataIsLoading}>
                                    {t("Restore")}
                                </Button>

                                <Button color="primary" type="submit" disabled={profileMutationIsLoading || userDataIsLoading}>
                                    {profileMutationIsLoading ? (
                                        <>
                                            <Spinner
                                                animation='border'
                                                variant='danger'
                                                size='sm'
                                                className='me-1'
                                            />
                                            {t("Saving")}...
                                        </>
                                    ) : (
                                        <>{t("Save")}</>
                                    )}
                                    
                                </Button>
                            </Col>
                        </Row>
                    </AvForm>

                    {userIsAnalystAdmin && (
                        <AvForm className='needs-validation' onValidSubmit={handleNotificationsSettingsSubmit}>
                            <Row className="mb-4">
                                <Col sm="12">
                                    { renderNotificationsSettings() }
                                </Col>
                            </Row>

                            <Row>
                                <Col sm="12" className="d-flex justify-content-end">
                                    <Button onClick={refetchNotificationsSettings} color="primary" type="button" outline className="me-2" disabled={notificationSettingsMutationIsLoading || userDataIsLoading || notificationsSettingIsLoading}>
                                        {t("Restore")}
                                    </Button>

                                    <Button color="primary" type="submit" disabled={notificationSettingsMutationIsLoading || userDataIsLoading || notificationsSettingIsLoading}>
                                        {notificationSettingsMutationIsLoading ? (
                                            <>
                                                <Spinner
                                                    animation='border'
                                                    variant='danger'
                                                    size='sm'
                                                    className='me-1'
                                                />
                                                {t("Saving")}...
                                            </>
                                        ) : (
                                            <>{t("Save")}</>
                                        )}
                                        
                                    </Button>
                                </Col>
                            </Row>
                        </AvForm>
                    )}
                </CardBody>
            </Card>
        )
    }

    return (
        <>
            <Modal size='lg' 
                scrollable={true} 
                isOpen={showPrivateApprovalModal} 
                backdrop='static'>

                <ModalHeader>
                    {t('Private email removal')}
                </ModalHeader>
                
                <ModalBody>
                    <AvForm className='needs-validation'>
                        <Alert color='warning' className='alert-dismissible fade show'>
                            <p>
                                {t('Attention')}!
                                <br/>
                                {t('Please note that removing your private email address disables the platform to send you notifications regarding your anonymous reports. You will not receive further notifications and have to log in to the platform.')}
                            </p>
                        </Alert>

                        <ModalFooter>
                            <Button color='primary' className='waves-effect waves-light' type='button' onClick={() => {
                                setShowPrivateApprovalModal(false);    
                                handleProfileFormSubmit();
                            }}>
                                {t('Approve')}
                            </Button>

                            <Button color='primary' outline className='waves-effect waves-light' type='button' onClick={() => setShowPrivateApprovalModal(false)}>
                                {t('Cancel')}
                            </Button>
                        </ModalFooter>
                    </AvForm>
                </ModalBody>
            </Modal>

            <div className="page-content profile-page">
                <Container fluid>
                    <Breadcrumbs title="Account Settings" breadcrumbItems={[]}/>
                    <Row>
                        <Col sm="12">
                            { renderProfileForm() }
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    )
}

const mapStatetoProps = (state) => {
    const { token, user } = state.Login;

    return { token, user };
};
  
const mapDispachToProps = (dispach) => {
    return {
        authCurrentUser: () => dispach(authCurrentUser())
    };
};

export default withNamespaces()(
    withRouter(connect(
        mapStatetoProps, 
        mapDispachToProps
    )(Profile))
);