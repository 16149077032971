import { takeEvery, all, fork, call, put } from "redux-saga/effects";

import {
    SAVE_ORGANIZATION_ACTIVE_MODULE_ON_REMOTE,
    SET_ORGANIZATION_ACTIVE_MODULE
} from './actionTypes';

import {
    fetch as fetchDefaultLanguageTranslations
} from "../../i18n";

import { setActiveModuleFeatures, setOrganizationActiveModule, onGetInfoOrganizationSuccess, startFetchingNotifications } from "../actions";

import store from "../";

import {
    AUTH_API_DOMAIN_URL,
    MODULES_FEATURES_ROUTES,
    MODULES_SETTINGS_ROUTES
} from "../../common/constants";

import axios from "axios";

const sendSaveModuleRequest = (module) => {
    const { token } = store.getState().Login;

    if(!token) return true;

    return axios.post(
        `${AUTH_API_DOMAIN_URL}module.save`,
        {
            module: module,
        },
        {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        },
    );
}

const loadModuleFeaturesFromServer = (module) => {
    const { token } = store.getState().Login;
    const { id } = store.getState().Organization;

    const headers = {};

    if(token){
        headers.Authorization = `Bearer ${token}`;
    }

    return axios.post(
        MODULES_FEATURES_ROUTES[module],
        {
            orgId       :   id,
        },
        {
            headers
        },
    );
}

const loadModuleSettingsFromServer = (module) => {
    if(!MODULES_SETTINGS_ROUTES[module]) return false;
    const { token } = store.getState().Login;
    const { id, url } = store.getState().Organization;

    const headers = {};

    if(token){
        headers.Authorization = `Bearer ${token}`;
    }

    return axios.post(
        MODULES_SETTINGS_ROUTES[module],
        {
            id  :   id,
            url :   url
        },
        {
            headers
        },
    );
}

function* setOrganizationActiveModuleToStorage({ payload: selectedModule }) {
	try {
        yield 1;
        localStorage.setItem("module", selectedModule);

        document.documentElement.setAttribute('data-module', selectedModule)
	} catch (error) {}
}

function* saveModuleOnRemote({
    payload: module
}){
    try{
        yield 1;
        yield put(setOrganizationActiveModule(module));
        yield call(sendSaveModuleRequest, module);
    } catch(error) {}
}

function* loadActiveModuleFeatures({
    payload: module
}){
    try{
        yield 1;
        const {
            data    :   {
                data    :   moduleFeatures
            }
        } = yield call(loadModuleFeaturesFromServer, module);

        yield put(setActiveModuleFeatures({
            features: moduleFeatures
        }));
    } catch(error) {}
}

function* loadModuleSettings({
    payload: module
}){
    try{
        yield 1;
        const {
            data    :   {
                data    :   organizationInfo
            }
        } = yield call(loadModuleSettingsFromServer, module);

        yield call(fetchDefaultLanguageTranslations);
            
        yield put(onGetInfoOrganizationSuccess(organizationInfo));

        yield put(startFetchingNotifications());

    } catch(error) {}
}

function* watchSaveModuleOnRemote(){
    yield takeEvery(SAVE_ORGANIZATION_ACTIVE_MODULE_ON_REMOTE, saveModuleOnRemote);
}

function* watchActiveModuleChange(){
    yield takeEvery(SET_ORGANIZATION_ACTIVE_MODULE, setOrganizationActiveModuleToStorage);
    yield takeEvery(SET_ORGANIZATION_ACTIVE_MODULE, loadActiveModuleFeatures);
    yield takeEvery(SET_ORGANIZATION_ACTIVE_MODULE, loadModuleSettings);
}


function* modulesSaga() {
    yield all([
        fork(watchSaveModuleOnRemote),
        fork(watchActiveModuleChange)
      ]);
}

export default modulesSaga;